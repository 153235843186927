import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import GameTile from "@/app/AppShellGameTile.jsx";
import { ACTIVE_GAMES, GAME_ICONS } from "@/app/constants.mjs";
import BlitzLogo from "@/inline-assets/blitz.svg";
import BlitzWordmark from "@/inline-assets/wordmark.svg";
import { launchGame } from "@/library/actions.mjs";
import { devError } from "@/util/dev.mjs";
import { useInstalledGames } from "@/util/game-system-hooks.mjs";

const Container = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--sp-6);
  padding: var(--sp-6) 0;
  overflow: auto;

  .logo {
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    :nth-child(1) {
      width: auto;
      height: var(--sp-8);
    }
    :nth-child(2) {
      width: auto;
      height: var(--sp-4);
    }
  }

  .tag-line {
    color: var(--shade1);
  }

  .games {
    margin: var(--sp-4);
    display: grid;
    max-width: var(--sp-container);
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-column-gap: var(--sp-2_5);
    grid-row-gap: var(--sp-2);
  }
`;

export default function GettingStarted() {
  const { t } = useTranslation();
  const installedGames = useInstalledGames();
  const [launchingStateMap, setLaunchingStateMap] = useState({});

  const doLaunch = useCallback(
    (gameSymbol) => {
      if (launchingStateMap[gameSymbol]) return;
      setLaunchingStateMap({
        ...launchingStateMap,
        [gameSymbol]: true,
      });
      setTimeout(() => {
        setLaunchingStateMap({
          ...launchingStateMap,
          [gameSymbol]: false,
        });
      }, 5 * 1000);
      launchGame(gameSymbol).catch(devError);
    },
    [launchingStateMap],
  );

  return (
    <Container>
      <div className="logo">
        <BlitzLogo />
        <BlitzWordmark />
      </div>
      <div className="tag-line">
        {t(
          "common:onboarding.launch",
          "Launch your game and we will detect your profile",
        )}
      </div>
      <div className="games">
        {ACTIVE_GAMES.map((gameSymbol, i) => {
          const hasGame = installedGames.find((s) => s === gameSymbol);
          const isLaunching = launchingStateMap[gameSymbol];
          const isDisabled = !hasGame || isLaunching;
          let label = t("common:launchGame", "Launch Game");
          if (isLaunching) label = t("common:loading", "Loading...");
          if (!hasGame) label = t("common:noGame", "Game not found");

          return (
            <div key={i}>
              <GameTile gameSymbol={gameSymbol} showTitle={false} />
              <Button
                iconLeft={<img src={GAME_ICONS[gameSymbol]} />}
                block
                disabled={isDisabled}
                onClick={() => doLaunch(gameSymbol)}
              >
                {label}
              </Button>
            </div>
          );
        })}
      </div>
    </Container>
  );
}

export function meta() {
  return {
    title: ["common:launchGame", "Launch Game"],
    description: ["common:launchGame", "Launch Game"],
  };
}
